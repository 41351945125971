import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { calculateVideoContentType } from '~utils/playlist-helpers';
import ClickableText from '~components/buttons/ClickableText';
import MarketingVideoActionBar from '~components/video-action-bar/MarketingVideoActionBar';

const Author = loadable(() => import('../Author'));
const HtmlExcerpt = loadable(() => import('~components/HtmlExcerpt'));

const useStyles = makeStyles(theme => ({
  heading: {
    color: '#F1F1F1',
    fontFamily: 'Inter',
    fontSize: '35px',
    fontWeight: 700,
    lineHeight: '130%',
    marginTop: '21px',
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  },
  subheading: {
    color: '#F1F1F1',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '130%',
    marginTop: '6px',
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  description: {
    marginTop: '-3px',
    marginBottom: '-3px',
    '& > *': {
      color: '#BABABE',
      fontFamily: 'Inter !important',
      fontSize: '16px !important',
      lineHeight: '150% !important'
    },
    '& > div > *': {
      color: '#BABABE',
      fontFamily: 'Inter !important',
      fontSize: '16px !important',
      lineHeight: '150% !important'
    }
  },
  actionArea: {
    width: '100%',
    marginTop: '60px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    rowGap: '30px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '40px'
    }
  },
  readMoreBtn: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  }
}));

const CustomReadMore = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <ClickableText className={classes.readMoreBtn} {...rest}>
      {children}
    </ClickableText>
  );
};

CustomReadMore.propTypes = {
  children: PropTypes.node.isRequired
};

const VideoDescription = ({
  currentVideo,
  currentVideoIndex,
  numberOfVideos,
  title,
  author,
  isSingleVideo,
  labels,
  hasUserSufficientAccess,
  handleInsufficientAccess,
  showGiftPopup
}) => {
  const classes = useStyles();
  const contentType = calculateVideoContentType(isSingleVideo, labels.isAudio);
  const videoTitle =
    numberOfVideos > 1
      ? `${currentVideo.title} (Part ${currentVideoIndex + 1} of ${numberOfVideos})`
      : currentVideo.title;

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="stretch">
        <Grid item xs={12} sm={8} md={9}>
          <div>
            <h1 className={classes.heading}>{videoTitle}</h1>
            <h2 className={classes.subheading}>{title}</h2>
            <HtmlExcerpt
              html={currentVideo.description.html}
              additionalClassName={classes.description}
              externalClassName={classes.description}
              CustomReadMoreBtn={CustomReadMore}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <div className={classes.actionArea}>
            <Author {...author} />
            <MarketingVideoActionBar
              title={title}
              videoTitle={currentVideo.title}
              contentType={contentType.toLowerCase()}
              showGiftPopup={showGiftPopup}
              videoUid={currentVideo.uid}
              hasUserSufficientAccess={hasUserSufficientAccess}
              handleInsufficientAccess={handleInsufficientAccess}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

VideoDescription.propTypes = {
  currentVideoIndex: PropTypes.number,
  numberOfVideos: PropTypes.number,
  currentVideo: PropTypes.shape({
    transcript: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    wistia_url: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    description: PropTypes.shape({ html: PropTypes.string.isRequired }).isRequired,
    uid: PropTypes.string.isRequired,
    prismicId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  showGiftPopup: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.shape({ name: PropTypes.string }).isRequired,
  isSingleVideo: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    isAudio: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    isPremium: PropTypes.bool.isRequired,
    isProducer: PropTypes.bool.isRequired
  }).isRequired,
  hasUserSufficientAccess: PropTypes.bool.isRequired,
  handleInsufficientAccess: PropTypes.func.isRequired
};

VideoDescription.defaultProps = {
  currentVideoIndex: 0,
  numberOfVideos: 1
};

export default VideoDescription;
